<!--
 * @Author: your name
 * @Date: 2020-12-03 13:21:26
 * @LastEditTime: 2021-04-02 18:51:18
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \one\src\views\test\index.vue
-->
<template>
  <div class="allclass all">
    <van-nav-bar title="报告内容" left-arrow @click-left="onClickLeft">
      <template #right>
        <img @click="clickshare2" class="fen" :src="fenxiang" alt="" />
      </template>
    </van-nav-bar>
    <div class="aaa">
      <div class="port"><img src="../../assets/luoboreport.png" /></div>
      <div class="yuanqi">
        <h1>员工背景信息调查报告</h1>
        <h2>委托公司：{{ report.entrusted_company }}</h2>
        <table class="topta">
          <tr>
            <td>候选人：</td>
            <td>{{ report.name }}</td>
          </tr>
          <tr>
            <td>报告编号：</td>
            <td>{{ baskNo }}</td>
          </tr>
          <tr>
            <td>调查日期：</td>
            <td>{{ report.report_data.back_time }}</td>
          </tr>
          <tr>
            <td>报告版本：</td>
            <td>{{ report.report_data.product_edition }}</td>
          </tr>
        </table>
      </div>
      <div class="shengming">
        <p>声明：</p>
        <p>
          {{ allmsg.statement1 }}
        </p>
      </div>
      <div class="tao">{{ report.product_edition }}</div>
      <div class="bomsg">
        <div class="twoh">基础信息</div>
        <table>
          <tr>
            <td>候选人姓名</td>
            <td>{{ report.name }}</td>
            <td>报告编号</td>
            <td>{{ baskNo }}</td>
          </tr>
          <tr>
            <td>性别</td>
            <td>{{ report.report_data.sex }}</td>
            <td>客户名称</td>
            <td>{{ report.entrusted_company }}</td>
          </tr>
          <tr>
            <td>身份证号</td>
            <td>
              {{
                report.idCardCode.replace(/^(.{4})(?:\d+)(.{4})$/, "$1******$2")
              }}
            </td>
            <td>报告版本</td>
            <td>{{ report.report_data.product_edition }}</td>
          </tr>
        </table>
        <div class="twoh">查询报告摘要</div>
        <div class="selectproject">查询项目</div>
        <!-- <table>
          <tr v-for="(item, index) in oneArray" :key="index">
            <td>{{ item.api_name }}</td>
            <td>
              <img
                v-if="item.back_results == 10"
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB0AAAAcCAYAAACdz7SqAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAchJREFUeNq8lr9OwzAQxtOKvRVrB8IDVMrE2kowIDEQnqDuG6RMjGVjI6wsTd+gCxIDQ2emIgY2VAYGthQJsSCF79ClcoPt/MHhpJPT2vEvn332XSNJEue/bavIoMbtURuNz+7Bd6TuFXwBn5EnhzfL3PlMShkWsLcKCpnCxya4FgpgH02UUVXUSH0AcFQYCqBAM7GwfVOARfbPpgLoWwKSDTBfaFSKAS4HRctywJ5A8UynNKoBSBZyUG5COXB6NR1NCkahUipszB51Tx0spTPfu8h2BSqobwM46Oz/PMdfH7/UYjW9NZR/tGwBVwAGT9eqYZ6stK0aQctELjoHpYD9+zNn+fmmGurmQlObdEdasAq4eH82fmTT1Dl8vDSCqwBlaKxU8XqnBVcFyqkt1u4XwClQBve2u1WAy41rEBEcmyKYQCl4nUpKKoTtUsqT93RmjNDMUlcAvqQ5Vq4cKBsM8sB06PtYWnouASQb67IMqT2u4e4lla7uyAjO+rZNaM8pvia2cQdnbIR558bLgQcMLSm+wnxhmcLM44i2Xphpr0G8sOCscF5SNZWgrg6YW/cqCjafM4VcYTzwTZMW2/Gfiu267FuAAQCFj/CRykdsWAAAAABJRU5ErkJggg=="
              />
              <img
                v-else
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAcCAYAAAByDd+UAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAZVJREFUeNpi/P//PwM9AQsxin4FCQYAKRA2AGJ9NOmDQHwBiBewrXt/gZBZjPh8CLQoAUg1ALE8kR4AWd4AtPgASRYCLVIAuRiI7ckMuYlASwuIshBoGSjYQC7kpzC6LgKxA9DiD8iCTFh8htcy5vAKBqAhYMykY4PPQn2oWQw4LQSCDVTwGYqlQE9MwGohUKIBSwqkBsiHRhPCQqCAAJAqoGH2a0D3YQCVgxId+EPTB4qFtAYByBY60MFCA2QLiQ7O/68fwdn/7l8mxUJIkEITDPHgFcJChq8fSfYmEwOdARN60UNzC6E00WHz78oRSFxePUqqXQ+QLTxAB88dQK6AQWWoP7E6/67sREmtpFgIr56AqfUDDUubhcC0koCeShuIinSnKAbW5i1gzKioS3ZZCqrfJkCbCPjbJKJyDIza1hDMTVSANALNfoArHwaQkmKJABuBljUQ08TYQELDCV+DKgA9n+NqRAlALaV9I4oKzcQCfO1TRmJa3tBgToBWMQZI2echtAQBhcYG5MRBkYXUBAABBgDH15Rzn8PBRAAAAABJRU5ErkJggg=="
              />
            </td>
          </tr>
        </table> -->
        <table>
          <tr v-for="(item, index) in allarry" :key="index">
            <td>{{ index }}</td>
            <td>
              <img
                v-if="item.thestatus == 10"
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB0AAAAcCAYAAACdz7SqAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAchJREFUeNq8lr9OwzAQxtOKvRVrB8IDVMrE2kowIDEQnqDuG6RMjGVjI6wsTd+gCxIDQ2emIgY2VAYGthQJsSCF79ClcoPt/MHhpJPT2vEvn332XSNJEue/bavIoMbtURuNz+7Bd6TuFXwBn5EnhzfL3PlMShkWsLcKCpnCxya4FgpgH02UUVXUSH0AcFQYCqBAM7GwfVOARfbPpgLoWwKSDTBfaFSKAS4HRctywJ5A8UynNKoBSBZyUG5COXB6NR1NCkahUipszB51Tx0spTPfu8h2BSqobwM46Oz/PMdfH7/UYjW9NZR/tGwBVwAGT9eqYZ6stK0aQctELjoHpYD9+zNn+fmmGurmQlObdEdasAq4eH82fmTT1Dl8vDSCqwBlaKxU8XqnBVcFyqkt1u4XwClQBve2u1WAy41rEBEcmyKYQCl4nUpKKoTtUsqT93RmjNDMUlcAvqQ5Vq4cKBsM8sB06PtYWnouASQb67IMqT2u4e4lla7uyAjO+rZNaM8pvia2cQdnbIR558bLgQcMLSm+wnxhmcLM44i2Xphpr0G8sOCscF5SNZWgrg6YW/cqCjafM4VcYTzwTZMW2/Gfiu267FuAAQCFj/CRykdsWAAAAABJRU5ErkJggg=="
              />
              <img
                v-else
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAcCAYAAAByDd+UAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAZVJREFUeNpi/P//PwM9AQsxin4FCQYAKRA2AGJ9NOmDQHwBiBewrXt/gZBZjPh8CLQoAUg1ALE8kR4AWd4AtPgASRYCLVIAuRiI7ckMuYlASwuIshBoGSjYQC7kpzC6LgKxA9DiD8iCTFh8htcy5vAKBqAhYMykY4PPQn2oWQw4LQSCDVTwGYqlQE9MwGohUKIBSwqkBsiHRhPCQqCAAJAqoGH2a0D3YQCVgxId+EPTB4qFtAYByBY60MFCA2QLiQ7O/68fwdn/7l8mxUJIkEITDPHgFcJChq8fSfYmEwOdARN60UNzC6E00WHz78oRSFxePUqqXQ+QLTxAB88dQK6AQWWoP7E6/67sREmtpFgIr56AqfUDDUubhcC0koCeShuIinSnKAbW5i1gzKioS3ZZCqrfJkCbCPjbJKJyDIza1hDMTVSANALNfoArHwaQkmKJABuBljUQ08TYQELDCV+DKgA9n+NqRAlALaV9I4oKzcQCfO1TRmJa3tBgToBWMQZI2echtAQBhcYG5MRBkYXUBAABBgDH15Rzn8PBRAAAAABJRU5ErkJggg=="
              />
            </td>
          </tr>
        </table>
        <div v-for="(item, name) in allarry" :key="name">
          <div class="twoh">{{ name }}</div>
          <div class="selectproject">查询项目：{{ name }}</div>
          <table>
            <tr>
              <th>查询项目</th>
              <th>查询结果</th>
              <th>数据来源</th>
              <th>查询结果</th>
            </tr>
            <tr v-for="(itemes, indexes) in item" :key="indexes">
              <td>{{ itemes.api_name }}</td>
              <td
                v-if="
                  itemes.parameters == 'detailJudgeStruct' &&
                  itemes.description_back_results.indexOf('{') != -1
                "
              >
                <p>
                  "案由"："{{
                    JSON.parse(itemes.description_back_results).reason
                  }}",
                </p>
                <p>
                  "案由标签"："{{
                    JSON.parse(itemes.description_back_results).tag
                  }}",
                </p>
                <p>
                  "法院"："{{
                    JSON.parse(itemes.description_back_results).court
                  }}",
                </p>
                <p>
                  "查询主体立场"："{{
                    JSON.parse(itemes.description_back_results).standPoint
                  }}",
                </p>
                <p>
                  "裁判日期"："{{
                    JSON.parse(itemes.description_back_results).judgeDate
                  }}",
                </p>
                <span>"匹配文本缩略内容"：</span
                ><span
                  v-html="JSON.parse(itemes.description_back_results).content"
                ></span>
                <p>
                  "标题"："{{
                    JSON.parse(itemes.description_back_results).title
                  }}",
                </p>
                <p>
                  "案号"："{{
                    JSON.parse(itemes.description_back_results).caseCode
                  }}",
                </p>
                <p>
                  "败胜诉"："{{
                    JSON.parse(itemes.description_back_results).winLosse
                  }}",
                </p>
                <p>
                  "案件金额"："{{
                    JSON.parse(itemes.description_back_results).realPriceSum
                  }}",
                </p>
                <p>
                  "公布日期"："{{
                    JSON.parse(itemes.description_back_results).publishDate
                  }}"
                </p>
              </td>
              <td
                v-if="
                  itemes.parameters == 'bzxrList' &&
                  itemes.description_back_results.indexOf('{') != -1
                "
              >
                <p>
                  "主体名称"："{{
                    JSON.parse(itemes.description_back_results).entityName
                  }}"
                </p>
                <p>
                  "主体代码"："{{
                    JSON.parse(itemes.description_back_results).entityId
                  }}"
                </p>
                <p>
                  "法院"："{{
                    JSON.parse(itemes.description_back_results).courtName
                  }}"
                </p>
                <p>
                  "案件状态"："{{
                    JSON.parse(itemes.description_back_results).caseState
                  }}"
                </p>
                <p>
                  "案号"："{{
                    JSON.parse(itemes.description_back_results).caseCode
                  }}"
                </p>
                <p>
                  "执行标的"："{{
                    JSON.parse(itemes.description_back_results).execMoney
                  }}"
                </p>
                <p>
                  "立案时间"："{{
                    JSON.parse(itemes.description_back_results).regDate
                  }}"
                </p>
                <p>
                  "命中类型(被执行人、案件当事人、曾用名)"："{{
                    JSON.parse(itemes.description_back_results).relateType
                  }}"
                </p>
              </td>
              <td
                v-if="
                  itemes.parameters == 'sxrListv4' &&
                  itemes.description_back_results.indexOf('{') != -1
                "
              >
                <p>
                  "主体名称"："{{
                    JSON.parse(itemes.description_back_results).entityName
                  }}"
                </p>
                <p>
                  "主体代码"："{{
                    JSON.parse(itemes.description_back_results).entityId
                  }}"
                </p>
                <p>
                  "案号"："{{
                    JSON.parse(itemes.description_back_results).caseCode
                  }}"
                </p>
                <p>
                  "年龄"："{{
                    JSON.parse(itemes.description_back_results).age
                  }}"
                </p>
                <p>
                  "性别"："{{
                    JSON.parse(itemes.description_back_results).sex
                  }}"
                </p>
                <p>
                  "法定代表人或者负责人姓名"："{{
                    JSON.parse(itemes.description_back_results).bussinessEntity
                  }}"
                </p>
                <p>
                  "法院"："{{
                    JSON.parse(itemes.description_back_results).courtName
                  }}"
                </p>
                <p>
                  "省份"："{{
                    JSON.parse(itemes.description_back_results).areaName
                  }}"
                </p>
                <p>
                  "类型"："{{
                    JSON.parse(itemes.description_back_results).partyTypeName
                  }}"
                </p>
                <p>
                  "执行依据文号"："{{
                    JSON.parse(itemes.description_back_results).gistId
                  }}"
                </p>
                <p>
                  "立案时间"："{{
                    JSON.parse(itemes.description_back_results).regDate
                  }}"
                </p>
                <p>
                  "做出执行依据单位"："{{
                    JSON.parse(itemes.description_back_results).gistUnit
                  }}"
                </p>
                <p>
                  "生效法律文书确定的义务"："{{
                    JSON.parse(itemes.description_back_results).duty
                  }}"
                </p>
                <p>
                  "被执行人的履行情况"："{{
                    JSON.parse(itemes.description_back_results).performance
                  }}"
                </p>
                <p>
                  "已履行"："{{
                    JSON.parse(itemes.description_back_results).performedPart
                  }}"
                </p>
                <p>
                  "未履行"："{{
                    JSON.parse(itemes.description_back_results).unPerformPart
                  }}"
                </p>
                <p>
                  "失信类型"："{{
                    JSON.parse(itemes.description_back_results).disruptTypeName
                  }}"
                </p>
                <p>
                  "发布日期"："{{
                    JSON.parse(itemes.description_back_results).publishDate
                  }}"
                </p>
                <p>
                  "命中类型(失信被执行人、关联案件、曾用名)"："{{
                    JSON.parse(itemes.description_back_results).relateType
                  }}"
                </p>
                <p>
                  "下架状态"："{{
                    JSON.parse(itemes.description_back_results).status
                  }}"
                </p>
              </td>
              <td
                v-if="
                  itemes.parameters == 'limitConsumee' &&
                  itemes.description_back_results.indexOf('{') != -1
                "
              >
                <p>
                  "案号"："{{
                    JSON.parse(itemes.description_back_results).caseCode
                  }}"
                </p>
                <p>
                  "法院"："{{
                    JSON.parse(itemes.description_back_results).courtName
                  }}"
                </p>
                <p>
                  "日期"："{{
                    JSON.parse(itemes.description_back_results).regDate
                  }}"
                </p>
                <p>
                  "公司"："{{
                    JSON.parse(itemes.description_back_results).company
                  }}"
                </p>
                <p>
                  "编码"："{{
                    JSON.parse(itemes.description_back_results).entityId
                  }}"
                </p>
                <p>
                  "姓名"："{{
                    JSON.parse(itemes.description_back_results).entityName
                  }}"
                </p>
                <p>
                  "状态"："{{
                    JSON.parse(itemes.description_back_results).state
                  }}"
                </p>
              </td>
              <td
                v-if="
                  itemes.parameters == 'educationInfo_query' &&
                  itemes.description_back_results.indexOf('{') != -1
                "
              >
                <div v-if="itemes.description_back_results.indexOf('{') != -1">
                  <p>
                    "入学时间"："{{
                      JSON.parse(itemes.description_back_results).admissionDate
                    }}"
                  </p>
                  <p>
                    "毕（结）业日期"："{{
                      JSON.parse(itemes.description_back_results)
                        .graduationDate
                    }}"
                  </p>
                  <p>
                    "学校名称"："{{
                      JSON.parse(itemes.description_back_results).school
                    }}"
                  </p>
                  <p>
                    "专业"："{{
                      JSON.parse(itemes.description_back_results).major
                    }}"
                  </p>
                  <p>
                    "学历类别"："{{
                      JSON.parse(itemes.description_back_results).educationType
                    }}"
                  </p>
                  <p>
                    "学制"："{{
                      JSON.parse(itemes.description_back_results).schoolSystem
                    }}"
                  </p>
                  <p>
                    "学习形式"："{{
                      JSON.parse(itemes.description_back_results).learningForm
                    }}"
                  </p>
                  <p>
                    "层次"："{{
                      JSON.parse(itemes.description_back_results)
                        .educationLevel
                    }}"
                  </p>
                  <p>
                    "毕（结）业"："{{
                      JSON.parse(itemes.description_back_results).graduation
                    }}"
                  </p>
                  <p>
                    "校（院）长姓名"："{{
                      JSON.parse(itemes.description_back_results).presidentName
                    }}"
                  </p>
                  <p>
                    "证书编号"："{{
                      JSON.parse(itemes.description_back_results).certificateNo
                    }}"
                  </p>
                </div>
                <div v-else>{{ itemes.description_back_results }}</div>
              </td>
              <td v-else>
                {{
                  itemes.description_back_results.split(",").length > 1
                    ? itemes.description_back_results
                        .split(",")[1]
                        .replace(/^(.{4})(?:\d+)(.{4})$/, "$1******$2")
                    : itemes.description_back_results
                }}
              </td>
              <td>{{ itemes.api }}</td>
              <td>
                <img
                  v-if="itemes.back_results == 10"
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB0AAAAcCAYAAACdz7SqAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAchJREFUeNq8lr9OwzAQxtOKvRVrB8IDVMrE2kowIDEQnqDuG6RMjGVjI6wsTd+gCxIDQ2emIgY2VAYGthQJsSCF79ClcoPt/MHhpJPT2vEvn332XSNJEue/bavIoMbtURuNz+7Bd6TuFXwBn5EnhzfL3PlMShkWsLcKCpnCxya4FgpgH02UUVXUSH0AcFQYCqBAM7GwfVOARfbPpgLoWwKSDTBfaFSKAS4HRctywJ5A8UynNKoBSBZyUG5COXB6NR1NCkahUipszB51Tx0spTPfu8h2BSqobwM46Oz/PMdfH7/UYjW9NZR/tGwBVwAGT9eqYZ6stK0aQctELjoHpYD9+zNn+fmmGurmQlObdEdasAq4eH82fmTT1Dl8vDSCqwBlaKxU8XqnBVcFyqkt1u4XwClQBve2u1WAy41rEBEcmyKYQCl4nUpKKoTtUsqT93RmjNDMUlcAvqQ5Vq4cKBsM8sB06PtYWnouASQb67IMqT2u4e4lla7uyAjO+rZNaM8pvia2cQdnbIR558bLgQcMLSm+wnxhmcLM44i2Xphpr0G8sOCscF5SNZWgrg6YW/cqCjafM4VcYTzwTZMW2/Gfiu267FuAAQCFj/CRykdsWAAAAABJRU5ErkJggg=="
                />
                <img
                  v-else
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAcCAYAAAByDd+UAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAZVJREFUeNpi/P//PwM9AQsxin4FCQYAKRA2AGJ9NOmDQHwBiBewrXt/gZBZjPh8CLQoAUg1ALE8kR4AWd4AtPgASRYCLVIAuRiI7ckMuYlASwuIshBoGSjYQC7kpzC6LgKxA9DiD8iCTFh8htcy5vAKBqAhYMykY4PPQn2oWQw4LQSCDVTwGYqlQE9MwGohUKIBSwqkBsiHRhPCQqCAAJAqoGH2a0D3YQCVgxId+EPTB4qFtAYByBY60MFCA2QLiQ7O/68fwdn/7l8mxUJIkEITDPHgFcJChq8fSfYmEwOdARN60UNzC6E00WHz78oRSFxePUqqXQ+QLTxAB88dQK6AQWWoP7E6/67sREmtpFgIr56AqfUDDUubhcC0koCeShuIinSnKAbW5i1gzKioS3ZZCqrfJkCbCPjbJKJyDIza1hDMTVSANALNfoArHwaQkmKJABuBljUQ08TYQELDCV+DKgA9n+NqRAlALaV9I4oKzcQCfO1TRmJa3tBgToBWMQZI2echtAQBhcYG5MRBkYXUBAABBgDH15Rzn8PBRAAAAABJRU5ErkJggg=="
                />
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
    <sharepage ref="sharitid"></sharepage>
    <share ref="shareid"></share>
  </div>
</template>
<script>
import fenxiang from "../../assets/fenxiang.png";
import sharepage from "../../views/sharepage/index";
import share from "../../components/share/index";
export default {
  components: {
    sharepage,
    share,
  },
  data() {
    return {
      fenxiang: fenxiang,
      name: "",
      userback: [],
      obj: {},
      allmsg: "", //所有信息
      profile: "", //员工档案信息
      report: {
        idCardCode: "",
        report_data: {
          back_time: "",
        },
      }, //报告
      selectallArray: "", //查询项目（所有）
      baskNo: "", //编号
      allarry: [], //所有项目
      oneArray: [], //所有单个项目
      isshare: false,
      isshare2: false,
      isreload: true, //是否刷新页面
    };
  },
  methods: {
    okmeth() {
      let wx = navigator.userAgent.toLowerCase();
      if (wx.match(/MicroMessenger/i) == "micromessenger") {
        this.isshare = false;
        return;
      } else {
        this.isshare2 = false;
      }
    },
    clickshare2() {
      this.$refs.shareid.clickshare();
    },
    childsharemeth() {
      this.clickshare();
      this.$refs.sharitid.mountedmeth();
    },
    clickshare() {
      let obj = JSON.stringify({
        sharetitle: "查看报告",
        sharedesc: "点击查看报告",
        isshare: "1",
        z_id: this.$route.query.z_id,
        r_id: this.$route.query.r_id,
        share_phone:localStorage.getItem("phone")
      });
      localStorage.setItem("shareobj", obj);
      // window.location = "/sharepage";
    },

    // savePdf() {
    //   this.$PDFSave(this.$refs.exportPdf, "我的文件");
    // },
    onClickLeft() {
      this.$router.go(-1);
    },
    getNo(data) {
      var text = "";
      if (data) {
        text = data.split(" ").join("").split("-").join("").split(":").join("");
      }
      return text;
    },

    // 查询项目（所有）
    selectallmeth(msg) {
      this.allarry = msg;
      Object.keys(msg).forEach((element) => {
        this.allarry[element].thestatus = this.selectonemeth(
          msg[element],
          element
        );
      });
      // for(let i of msg){
      //
      // }
    },

    //重组所有项目
    newallarray(msg) {},

    //所有单个项目
    selectonemeth(msg, element) {
      let thestatus = 10;
      for (let i = 0; i < msg.length; i++) {
        // if(msg[i].api_name = "个人涉诉记录查询"){
        //
        // }
        if (msg[i].back_results == "40") {
          thestatus = 40;
        }

        if (msg[i].api_name == "个人涉诉记录查询") {

          // JSON.parse(this.allarry[element][i].description_back_results).content = (JSON.parse(msg[i].description_back_results).content+"").replace(new RegExp('<.*?>','g'),"");
          // JSON.parse(this.allarry[element][i].description_back_results).content = "123";
          // console.error("666666666",JSON.parse(this.allarry[element][i].description_back_results));
          // console.error("555555555",msg);
        }
        this.oneArray.push(msg[i]);
      }
      return thestatus;
    },
  },
  // beforeCreate(){
  //   if(this.isreload==true){
  //     location.reload();
  //     this.isreload = false;
  //   }
  // },
  mounted() {
    if (this.$route.query.isshare && this.$route.query.isshare == 1) {
      this.userback.z_id = this.$route.query.z_id;
      this.userback.r_id = this.$route.query.r_id;
    } else {
      // this.userback.z_id = this.$route.query.z_id;
      // this.userback.r_id = this.$route.query.r_id;
      this.userback.z_id = localStorage.getItem("z_id");
      this.userback.r_id = localStorage.getItem("r_id");
      this.clickshare();
    }
    this.$http
      .post("/firm/v1/Reportinfo/readNew", {
        reqType: "profile",
        id: this.userback.z_id, //用户id
        l_id: this.userback.r_id,
      })
      .then((res) => {
        let resdatamsg = JSON.parse(res.data);
        this.allmsg = resdatamsg.data; //所有信息
        this.profile = resdatamsg.data.profile; //员工档案信息
        this.report = resdatamsg.data.report; //报告

        this.baskNo = this.getNo(this.report.report_data.back_time); //编号

        this.selectallmeth(this.report.questLogsDate.info);
      });
  },
};
</script>
<style scoped lang="scss">
#okclass {
  margin-left: 50%;
  transform: translateX(-50%);
  margin-top: 0.3rem;
  border: none;
  padding: 0.1rem 0.3rem;
  border-radius: 0.1rem;
  background-color: antiquewhite;
}
.tipclass {
  position: fixed;
  top: 0.2rem;
  right: 0;
  z-index: 1000;
  background: #ffffff;
  padding: 0.2rem;
  border-radius: 0.2rem;
}
.tipclass2 {
  position: fixed;
  bottom: 0.2rem;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;
  background: #ffffff;
  padding: 0.2rem;
  border-radius: 0.2rem;
}
.aaa {
  margin-top: 0.3rem;
}
.all {
  padding-bottom: 1rem;
}
.namemsg {
  color: #a7a7a7;
}
.namemsg + div {
  font-size: 0.35rem;
  line-height: 0.3rem;
  font-weight: 550;
}
.phonemsg {
  color: #a7a7a7;
}
.phonemsg + p + div,
.phonemsg + p + div + div {
  margin-left: 0.2rem;
  background-color: #67c23a;
  color: #ffffff;
  font-weight: normal;
  padding: 0 0.2rem;
  border-radius: 0.08rem;
}

.topmsgall {
  padding: 0.2rem;
  padding-bottom: 0.4rem;
  margin-bottom: 0.5rem;
  border-bottom-color: #e4e7ed;
  border-bottom-style: solid;
  border-bottom-width: 1px;
}
.toponemsg {
  display: flex;
  align-items: center;
}
.toponemsg img {
  width: 0.9rem;
  height: 0.9rem;
  border-radius: 50%;
  overflow: hidden;
}
.toponemsg > div {
  display: flex;
}
.toponemsg > div > div:first-child {
  margin: 0 0 0 0.1rem;
}
.toptwomsg {
  display: flex;
  align-items: center;
  margin-left: 1rem;
}
// .toptwo
.topta {
  border: none;
  width: 60%;
  margin: auto;
  text-align: left;
  font-size: 0.2rem;
  margin-top: 0.2rem;
}
.topta td {
  border: none;
  padding: 0.1rem 0;
  font-size: 0.25rem;
}
.shengming {
  margin: 0.3rem;
}
.tao {
  margin: 0.3rem;
  margin-top: 0.6rem;
  font-size: 0.4rem;
}
.shengming p:first-child {
  font-size: 0.28rem;
  margin-top: 0.6rem;
  margin-bottom: 0.3rem;
}
.shengming p:nth-child(2) {
  padding: 0 0.5rem;
}
.bomsg {
  margin: 0 0.3rem;
}
.twoh {
  font-size: 0.3rem;
  margin: 0.3rem 0;
}
table {
  border-collapse: collapse;
  border: 2px solid #f2f6fc;
  width: 100%;
  margin: 0.3rem 0 0.6rem 0;
  // letter-spacing: 1px;
  // font-size: .3rem;
}
td {
  border: 2px solid #f2f6fc;
  padding: 0.1rem;
}
th {
  background-color: #edf3ff;
  color: #576170;
  padding: 0.1rem;
}
.selectproject {
  background-color: #ffcfae;
  border: 1px solid #ff4500;
  padding: 0.18rem;
  font-weight: bold;
}
.yuanqi {
  text-align: center;
}
.port {
  text-align: center;
}
.port img {
  width: 2.47rem;
}
h1 {
  margin: 0.4rem 0 0.8rem 0;
}
/deep/ .van-nav-bar .van-icon {
  color: #000000;
  font-size: 0.36rem;
}
/deep/ .van-nav-bar__title {
  max-width: 60%;
  margin: 0 auto;
  font-size: 0.34rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #141f33;
}
/deep/ .van-nav-bar__content {
  height: 0.88rem;
}
.fen {
  width: 0.4rem;
  height: 0.41rem;
}
.allclass {
  background-color: #ffffff;
  font-weight: bold;
}
.paytip {
  margin: 0.45rem 0.3rem;
  background-color: #fff2ed;
  color: #141f33;
  font-size: 0.28rem;
  padding: 0.26rem 0.3rem;
  font-weight: 500;
  border-radius: 10px;
}
.xinxi {
  display: flex;
  justify-content: space-between;
  margin: 0 0.3rem;
  padding: 0.21rem 0;
  border-bottom-color: #ebeef5;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  align-items: center;
}
.xinxi p {
  color: #616b80;
  font-size: 0.28rem;
}
.xinxi > div input {
  border: none;
}
.bt {
  margin-top: 2.83rem;
  margin-bottom: 1rem;
}
.xinxi input {
  text-align: right;
}
</style>